import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import '../styles/tiles.css';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';

// assets
import icon_add_white from '../assets/icon_add_white.png';

class Overview extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            arrayOfAreas: ([{id_kra: 1, title:"Loading...", plan_object: {id_plan: 1, quarter_1:"Loading...", quarter_2:"Loading...", quarter_3:"Loading...", quarter_4:"Loading...", year_vision:"Loading...", year: "Loading..."}},
                            {id_kra: 2, title:"Loading...", plan_object: {id_plan: 1, quarter_1:"Loading...", quarter_2:"Loading...", quarter_3:"Loading...", quarter_4:"Loading...", year_vision:"Loading...", year: "Loading..."}},
                            {id_kra: 3, title:"Loading...", plan_object: {id_plan: 1, quarter_1:"Loading...", quarter_2:"Loading...", quarter_3:"Loading...", quarter_4:"Loading...", year_vision:"Loading...", year: "Loading..."}},
                            {id_kra: 4, title:"Loading...", plan_object: {id_plan: 1, quarter_1:"Loading...", quarter_2:"Loading...", quarter_3:"Loading...", quarter_4:"Loading...", year_vision:"Loading...", year: "Loading..."}},
                            {id_kra: 5, title:"Loading...", plan_object: {id_plan: 1, quarter_1:"Loading...", quarter_2:"Loading...", quarter_3:"Loading...", quarter_4:"Loading...", year_vision:"Loading...", year: "Loading..."}}]),


        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        this.loadData();
    }

    loadData = () => {
        Axios.get("/api/kra/get").then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }

            var workArray = response.data.result;

            var workingList = response.data.result;
            response.data.result.forEach((element, index) => {
                workingList[index].plan_object = {id_plan: 1, quarter_1:"Loading...", quarter_2:"Loading...", quarter_3:"Loading...", quarter_4:"Loading...", year_vision:"Loading...", year: "Loading..."};
            });

            this.setState({ arrayOfAreas: workingList });
            this.loadAllPlans(response.data.result);
        });

    }

    loadAllPlans = async (areas) => {

        areas.forEach((element, index) => {
            var areaId = element.id_kra;

            Axios.get("/api/plan/get?kra="+areaId).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                if(response.data.result.length >= 1){


                    var workingList = areas;
                    workingList.forEach((element, index) => {
                        if(element.id_kra == areaId){
                            areas[index].plan_object = {
                                    id_plan: response.data.result[0].id_plan,
                                    quarter_1: response.data.result[0].quarter_1,
                                    quarter_2: response.data.result[0].quarter_2,
                                    quarter_3: response.data.result[0].quarter_3,
                                    quarter_4: response.data.result[0].quarter_4,
                                    year_vision: response.data.result[0].year_vision,
                                    year: response.data.result[0].year};
                        }
                    });
                    this.setState({ arrayOfAreas: workingList });
                }
            });
            //alert(element.id_kra);
            ///workingList[index].plan_object = {id_plan: 1, quarter_1:"Loading...", quarter_2:"Loading...", quarter_3:"Loading...", quarter_4:"Loading...", year_vision:"Loading...", year: "Loading..."};
        });


    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 70px)", overflowY: "scroll"}}>

                <div className="universalTile">
                    <div className="verticalStack">
                        <div className="horizontalStack">

                            <div className="flex"></div>

                            <span className="fontPoppinsSemiBold15">{"Přehled oblastí"}</span>

                            <div className="flex"></div>
                        </div>
                    </div>
                </div>

                <div className="horizontalStack" style={{marginLeft: 20, marginTop: 20, display: "flex"}}>

                    {this.state.arrayOfAreas.map(function (item,index) {
                        return (
                            <div key={index} className="universalTile" style={{flex: 1, margin: 0, marginRight: 20}}>
                                <span className="fontPoppinsSemiBold15">{item.title}</span>
                            </div>
                        )
                    }.bind(this))}

                </div>

                <div className="horizontalStack" style={{marginLeft: 20, marginTop: 20, display: "flex"}}>

                    {this.state.arrayOfAreas.map(function (item,index) {
                        return (
                            <div key={index} className="universalTile" style={{flex: 1, margin: 0, marginRight: 20}}>
                                <div className="verticalStack">
                                    <span className="fontPoppinsRegular13">{"Plán na "}{item.plan_object.year}{"/01:"}</span>
                                    <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "pre-wrap"}}>{item.plan_object.quarter_1}</span>
                                    <div style={{height: 10}}></div>
                                    <span className="fontPoppinsRegular13">{"Plán na "}{item.plan_object.year}{"/02:"}</span>
                                    <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "pre-wrap"}}>{item.plan_object.quarter_2}</span>
                                    <div style={{height: 10}}></div>
                                    <span className="fontPoppinsRegular13">{"Plán na "}{item.plan_object.year}{"/03:"}</span>
                                    <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "pre-wrap"}}>{item.plan_object.quarter_3}</span>
                                    <div style={{height: 10}}></div>
                                    <span className="fontPoppinsRegular13">{"Plán na "}{item.plan_object.year}{"/04:"}</span>
                                    <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "pre-wrap"}}>{item.plan_object.quarter_4}</span>
                                </div>
                            </div>
                        )
                    }.bind(this))}

                </div>

                <div className="horizontalStack" style={{marginLeft: 20, marginTop: 20, marginBottom: 20, display: "flex"}}>

                    {this.state.arrayOfAreas.map(function (item,index) {
                        return (
                            <div key={index} className="universalTile" style={{flex: 1, margin: 0, marginRight: 20}}>
                                <div className="verticalStack">
                                    <span className="fontPoppinsSemiBold13">{"Plán na "}{item.plan_object.year}{":"}</span>
                                    <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "pre-wrap"}}>{item.plan_object.year_vision}</span>
                                </div>
                            </div>
                        )
                    }.bind(this))}

                </div>

            </div>
        );
    }
}

export default Overview;
